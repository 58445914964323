<template>
  <div>
    <!-- myDiv 计算文本宽度高度 -->
    <div id="myDiv111"></div>
    <!-- myCanvas 也来计算文本宽度高度 -->
    <canvas id="myCanvas" style="display: none"></canvas>

    <div style="width: 100%; height: 10vh; visibility: hidden">
      <!-- antv-x6 -->
      <div id="container"></div>
    </div>

    <el-dialog
      class="outstanding_detail_dialog_zdh"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="aasd"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
      :title="outstanding_name"
    >
      <div id="outstanding_dialog_table_export">
        <div style="font-size: 18px !important; font-weight: 700">
          {{ outstanding_name }}
        </div>
        <el-table
          :data="modify_table_data(outstanding_map)"
          class="outstanding_dialog_table_class"
          :show-header="false"
          cell-class-name="outstanding_dialog_table_class_cell"
        >
          <el-table-column prop="name" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="outstanding_name">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="color" width="30">
            <template slot-scope="scope">
              <div
                class="outstanding_dialog_color"
                :style="'background-color:' + scope.row.color"
              ></div>
            </template>
          </el-table-column>
          <el-table-column prop="num" width="50"> </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Graph, DataUri } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import { getFile, getReleaseElements } from "@/network/home/index.js";
import get_water from "@/store/water.js";
import vmson from "@/utils/vmson";
import html2Canvas from "html2canvas";
import { mapGetters } from "vuex";
import {
  getNodeCoverage,
  get_file_coverage,
} from "@/network/coverage/index.js";

export default {
  props: {},
  data() {
    return {
      aasd: true,
      graph: "", // antv x6
      node_padding_width: 25,
      node_width: 250,
      graph_name: "",
      outstanding_type: "",
      id2color: {},
      outstanding_map: {},
      outstanding_name: "",
      water_area: [0, 0, 0, 0],
    };
  },
  computed: {
    ...mapGetters(["status", "fileType", "user_list"]),
    ...mapGetters(["watermask_switch", "watermask_value", "user_info"]),
  },
  mounted() {
    let val = "";

    if (document.getElementById("outstanding_detail_dialog")) {
      this.outstanding_type = document.getElementById(
        "outstanding_detail_dialog"
      ).attributes.outstanding_type.nodeValue;
      val = this.outstanding_type;
    } else {
      this.outstanding_type = "";
    }

    if (val == "sprintId") {
      this.outstanding_name = "Sprint";

      let color_map = [
        "#f07c82",
        "#5c2223",
        "#eeb8c3",
        "#813c85",
        "#0f59a4",
        "#101f30",
        "#134857",
        "#1ba784",
        "#248067",
        "#8cc269",
        "#fed71a",
        "#835e1d",
        "#f26b1f",
        "#cf4813",
        "#862617",
      ];
      const get_file = getFile(this.get_pid(), this.$route.params.file_key);
      const get_release = getReleaseElements(
        this.get_pid(),
        this.$route.params.file_key
      );
      let sprint_list = Object.values(
        JSON.parse(sessionStorage.getItem("_sprint"))
      );
      let sprint_map = {};
      for (let i of sprint_list) {
        sprint_map[i.sprintId] = i;
      }
      let type_set = {};
      this.id2color = {};
      Promise.all([get_file, get_release]).then((result) => {
        result[0].data.forEach((item) => {
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              let sprintId = i.sprintId ? i.sprintId : "null";

              if (sprintId in type_set) {
                type_set[sprintId].num++;
              } else {
                let col = color_map.shift();
                type_set[sprintId] = {
                  name:
                    sprintId == "null"
                      ? this.$t("canvas.wordView.null")
                      : sprint_map[sprintId].name,
                  color: col,
                  num: 1,
                };
              }
              this.id2color[item.key] = type_set[sprintId].color;
            }
          });
        });
        this.outstanding_map = type_set;
        this.graph_name = result[0].meta.name;
        this.init(result[0].data);
      });
    } else if (val.split("@")[0] == "coverage") {
      this.outstanding_name = this.$t("canvas.wordView.coverage");

      getFile(this.get_pid(), this.$route.params.file_key).then((file_res) => {
        let type_set = {};
        this.id2color = {};
        let all_key = [];
        for (let i of file_res.data) {
          all_key.push(i.key);
        }
        const params = {
          projectId: this.get_pid(),
        };
        getNodeCoverage(params).then((res) => {
          if (!res) {
            res = [];
          }
          // 获得已覆盖节点
          get_file_coverage(
            this.get_pid(),
            this.$route.params.file_key,
            val.split("@")[1]
          ).then((ress) => {
            let no_need = all_key.filter((node) => res.includes(node));
            let need = all_key.filter((node) => !res.includes(node));

            let need_has = need.filter((node) => ress.includes(node));
            let need_no = need.filter((node) => !ress.includes(node));

            type_set["need_has"] = {
              name: this.$t("canvas.wordView.need_has"),
              color: "#0CAD0C",
              num: need_has.length,
            };
            type_set["need_no"] = {
              name: this.$t("canvas.wordView.need_no"),
              color: "#D75455",
              num: need_no.length,
            };
            type_set["no_need"] = {
              name: this.$t("canvas.wordView.no_need"),
              color: "#5f5e5f",
              num: no_need.length,
            };

            for (let i of no_need) {
              this.id2color[i] = "#5f5e5f";
            }
            for (let i of need_no) {
              this.id2color[i] = "#D75455";
            }
            for (let i of need_has) {
              this.id2color[i] = "#0CAD0C";
            }
            this.outstanding_map = type_set;
            this.graph_name = file_res.meta.name;
            this.init(file_res.data);
            return;
          });
        });
      });
    } else {
      getFile(this.get_pid(), this.$route.params.file_key).then((file_res) => {
        let color_map_status = {
          TODO: ["#E87A90", "#F759AB", "#D75455", "#FF4D4F", "#FF7A45"],
          IN_PROGRESS: ["#2651A6", "#4968A6", "#4B7BA6", "#6B7FF2", "#597EF7"],
          DONE: ["#19BF5B", "#55AA55", "#0CAD0C", "#23B85E", "#2D882D"],
        };
        let color_map = [
          "#f07c82",
          "#5c2223",
          "#eeb8c3",
          "#813c85",
          "#0f59a4",
          "#101f30",
          "#134857",
          "#1ba784",
          "#248067",
          "#8cc269",
          "#fed71a",
          "#835e1d",
          "#f26b1f",
          "#cf4813",
          "#862617",
        ];
        let all_nodes = file_res.data;
        let type_set = {};
        this.id2color = {};
        let all_key = [];
        for (let i of all_nodes) {
          all_key.push(i.key);
          if (val == "status") {
            if (i.status in type_set) {
              type_set[i.status].num++;
            } else {
              type_set[i.status] = {
                name: this.status[i.status].name,
                color:
                  color_map_status[
                    this.status[i.status].statusCategory
                  ].shift(),
                num: 1,
              };
            }
          }

          if (val == "assignee") {
            let assign = i.assignee ? i.assignee : "null";

            if (assign in type_set) {
              type_set[assign].num++;
            } else {
              let col = color_map.shift();
              type_set[assign] = {
                name:
                  assign == "null"
                    ? this.$t("canvas.wordView.null")
                    : this.user_list[assign].nickname,
                color: col,
                num: 1,
              };
            }
          }

          if (val.split("@")[0] == "otherFile") {
            let filed_value = "no";
            let filed_name = this.$t("node.message.message1");

            for (let item of i.content) {
              if (item.fieldId == val.split("@")[1]) {
                filed_value = item.value ? item.value : "null";
                filed_name = item.value
                  ? item.name
                  : this.$t("canvas.wordView.null");
              }
            }

            if (filed_value in type_set) {
              type_set[filed_value].num++;
            } else {
              let col = color_map.shift();
              type_set[filed_value] = {
                name: filed_name,
                color: col,
                num: 1,
              };
            }
          }
        }

        this.outstanding_map = type_set;

        if (val == "status") {
          this.outstanding_name = this.$t("canvas.wordView.status");

          for (let i of all_nodes) {
            this.id2color[i.key] = type_set[i.status].color;
          }
        }

        if (val == "assignee") {
          this.outstanding_name = this.$t("canvas.wordView.assignee");

          for (let i of all_nodes) {
            let assign = i.assignee ? i.assignee : "null";
            this.id2color[i.key] = type_set[assign].color;
          }
        }

        if (val.split("@")[0] == "otherFile") {
          for (let i of all_nodes) {
            let filed_value = "no";
            for (let item of i.content) {
              if (item.fieldId == val.split("@")[1]) {
                filed_value = item.value ? item.value : "null";
                this.outstanding_name = item.name;
              }
            }
            this.id2color[i.key] = type_set[filed_value].color;
          }
        }

        if (val.length < 1) {
          for (let i of all_nodes) {
            this.id2color[i.key] = "#428bca";
          }
        }
        this.graph_name = file_res.meta.name;
        this.init(file_res.data);
      });
    }
  },
  methods: {
    modify_table_data(data) {
      let l = [];
      for (let i of Object.keys(data)) {
        l.push(data[i]);
      }
      return l;
    },

    list_2_tree(list) {
      let map = {},
        node,
        roots = [];
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
      }
      for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentid !== "") {
          list[map[node.parentid]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots[0];
    },
    get_graph_data(tree) {
      // 创建fta节点数据
      let data = [];
      tree.forEach((item) => {
        data.push(this.create_node(item));
      });
      // Hierarchy可以计算tree结构的数据每个节点的位置
      const result = Hierarchy.mindmap(this.list_2_tree(data), {
        direction: "H",
        getHeight(d) {
          return d.height;
        },
        getWidth(d) {
          return d.width;
        },
        getHGap(d) {
          return 40;
        },
        getVGap() {
          return 15;
        },
        getSide: () => {
          return "right";
        },
      });
      // let box = document.getElementById("myDiv111");
      // box.parentNode.removeChild(box);
      return result;
    },
    calculate_node_width_height(text) {
      // 根据文本，计算每个节点的宽
      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");
      ctx.font = "18px Arial";
      ctx.fillText(text, 0, 0);
      const width = ctx.measureText(text).width;

      return width;
    },
    new_calculate_node_width_height(text) {
      // 根据文本，计算每个节点的高
      const div = document.getElementById("myDiv111");
      div.innerText = text;

      return div.clientHeight;
    },
    create_node(tree_node) {
      let _this = this;
      // 创建节点
      let width = this.calculate_node_width_height(tree_node.topic);
      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic); //行高是25，有几行就25*几，10=padding top+bottom
      // node_width，如果计算出的width>this.node_width(规定的节点最大宽度)，那么就让节点=规定的最大宽度
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width;
      let data = {
        leaf: true,
        layer: tree_node.layer,
        parentid: tree_node.parentid,
        raw_width: width,
        topic: tree_node.topic,
        shape: "html",
        width: node_width,
        height: node_height,
        id: tree_node.id,
        html() {
          const node = document.createElement("div");

          const con = document.createElement("div");
          const con1 = document.createElement("div");

          con.style.display = "flex";
          con1.style.height = 20 + "px";

          node.id = "node" + tree_node.id;
          node.style.width = node_width + "px";
          node.style.lineHeight = "25px";
          node.style.background = _this.id2color[tree_node.key];
          node.style.color = "white";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.padding = "5px 10px";
          node.style.alignItems = "center";
          node.style.border = "1px solid " + _this.id2color[tree_node.key];
          node.style.borderRadius = "5px";
          node.style.font = "18px Arial";
          node.style.wordBreak = "break-all";
          node.style.zIndex = 99999999999;
          node.innerText = tree_node.topic;

          con.appendChild(node);
          con.appendChild(con1);
          return con;
        },
      };
      return data;
    },
    create_edge(source_node, target_node) {
      let data = {
        source: source_node, // 起始节点 id
        target: target_node, // 目标节点 id
        markup: [
          {
            tagName: "path",
            selector: "stroke",
          },
        ],
        attrs: {
          stroke: {
            fill: "none",
            stroke: "#8f8f8f",
            connection: true,
            strokeWidth: 2,
            strokeLinecap: "round",
          },
        },

        router: {
          name: "er",
          args: {
            direction: "R",
            offser: "center",
          },
        },
        connector: "rounded",
      };
      return data;
    },
    // drawWatermark(ctx, w, h) {
    //   const text = this.watermask_value + this.user_info.email.split("@")[0]; // 水印文本
    //   const fontSize = 26; // 水印字体大小
    //   const textHeight = fontSize * 8; // 假设文本高度是字体大小的1.1倍

    //   // 设定字体样式
    //   ctx.font = `normal 26px Microsoft Yahei`;
    //   ctx.fillStyle = "rgba(112, 113, 114, 0.8)"; // 半透明水印
    //   ctx.textAlign = "center";
    //   ctx.textBaseline = "middle";

    //   // 计算每行和每列的水印数量
    //   const columns = Math.ceil(w / (text.length * fontSize * 1.5)); // 假设水印之间有间隔
    //   const rows = Math.ceil(h / textHeight);
    //   ctx.rotate((30 * Math.PI) / 180);

    //   for (let i = -rows; i < rows * 2; i++) {
    //     for (let j = -columns; j < columns * 2; j++) {
    //       // 计算文本位置
    //       const x = j * (text.length * fontSize * 1.5);
    //       const y = i * textHeight;

    //       // 可以添加一些随机性，如旋转角度、位置偏移等
    //       // 绘制文本
    //       ctx.fillText(text, x, y);
    //     }
    //   }
    //   // ctx.rotate(-(30 * Math.PI) / 180);
    // },
    add_water_mask() {
      console.log(this.watermask_value + this.user_info.email.split("@")[0]);

      let bas = this.get_water_base64(
        this.watermask_value + this.user_info.email.split("@")[0]
      );

      let that = this;

      setTimeout(() => {
        let ddd = {
          x: this.water_area[0],
          y: this.water_area[2],
          width: this.water_area[1] - this.water_area[0],
          height: this.water_area[3] - this.water_area[2],
          shape: "html",
          id: "water_mask_share",
          zIndex: 1,
          html() {
            const con = document.createElement("div"); //

            con.style.width = that.water_area[1] - that.water_area[0] + "px";
            con.style.height = that.water_area[3] - that.water_area[2] + "px";
            con.style.backgroundImage = `url(${bas})`;
            con.style.backgroundRepeat = "repeat";

            return con;
          },
        };
        console.log(ddd);
        this.graph.addNode(ddd);
      }, 0);
    },
    get_water_base64(text) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = this.calculate_node_width_height(text);
      let height = this.new_calculate_node_width_height(text);

      const fontSize = 18;
      const fontFamily = "Arial";
      const textColor = "#e2e2e2";
      const textAlign = "center";
      canvas.width = Math.max(width, height);
      canvas.height = Math.max(width, height) + 100;

      ctx.rotate((25 * Math.PI) / 180);

      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillStyle = textColor;
      ctx.textAlign = textAlign;

      ctx.fillText(text, width / 2 + 10, height / 2);

      return canvas.toDataURL("image/png");
    },
    init(tree) {
      this.graph = new Graph({
        // 具体参数见官方文档 https://x6.antv.vision/zh/docs/api/graph/graph
        container: document.getElementById("container"),
        scroller: true,
        interacting: false,
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
      });

      let l = [];
      let min_x = 999;
      let min_y = 999;
      let max_x = -110;
      let max_y = -110;
      l.push(this.get_graph_data(tree));
      // 循环添加节点
      while (l.length > 0) {
        let n = l.shift();

        let d = n.data;
        d.x = n.x;
        d.y = n.y;

        min_x = Math.min(min_x, d.x);
        min_y = Math.min(min_y, d.y);

        max_x = Math.max(max_x, d.x + d.width);
        max_y = Math.max(max_y, d.y + d.height);
        this.water_area = [min_x, max_x, min_y, max_y];

        this.graph.addNode(d); // 添加节点
        if (n.data.parentid) {
          this.graph.addEdge(this.create_edge(n.data.id, n.data.parentid)); // 添加边
        }
        l = [...l, ...n.children];
      }

      this.graph.centerContent(); // 视图居中
      let that = this;
      this.add_water_mask();
      let ii = 0.5;

      setTimeout(() => {
        if (this.outstanding_type.length < 1) {
          this.graph.toPNG(
            (dataUri) => {
              let canvas = document.createElement("canvas");
              let context = canvas.getContext("2d");
              context.imageSmoothingEnabled = true;
              context.imageSmoothingQuality = 1;

              let base64Img = new Image();
              base64Img.src = dataUri;
              base64Img.onload = () => {
                let watermarkImg = new Image();
                watermarkImg.src = get_water();
                watermarkImg.onload = () => {
                  let scale = 2;

                  let w = base64Img.width > 700 ? 700 : base64Img.width * ii;
                  let h = (w / watermarkImg.width) * watermarkImg.height;

                  canvas.width = base64Img.width * scale;
                  canvas.height = (base64Img.height + h) * scale;

                  context.fillStyle = "white";
                  context.fillRect(0, 0, canvas.width, canvas.height);
                  // that.drawWatermark(context, canvas.width, canvas.height);

                  context.drawImage(
                    base64Img,
                    0,
                    0,
                    base64Img.width * scale,
                    base64Img.height * scale
                  );

                  context.imageSmoothingEnabled = true;
                  context.imageSmoothingQuality = 1;

                  let y = base64Img.height * scale;

                  context.drawImage(watermarkImg, 0, y, w * scale, h * scale);

                  context.imageSmoothingEnabled = true;
                  context.imageSmoothingQuality = 1;

                  let resultImg = canvas.toDataURL("image/png");
                  DataUri.downloadDataUri(resultImg, this.graph_name + ".png");
                  vmson.$emit("export_png_success");
                };
              };
            },
            {
              backgroundColor: "white",
              quality: 1,
              padding: {
                top: 20,
                right: 50,
                bottom: 20,
                left: 50,
              },
            }
          );
        } else {
          this.graph.toPNG(
            (dataUri) => {
              let base64Img = new Image();
              base64Img.src = dataUri;
              base64Img.onload = () => {
                let watermarkImg = new Image();
                watermarkImg.src = get_water();
                watermarkImg.onload = () => {
                  const content = document.getElementById(
                    "outstanding_dialog_table_export"
                  );

                  html2Canvas(content).then((tu_li_canvas) => {
                    let tu_li_base = tu_li_canvas.toDataURL("image/png");
                    let tu_li = new Image();
                    tu_li.src = tu_li_base;
                    tu_li.onload = () => {
                      let scale = 2;
                      let canvas = document.createElement("canvas");
                      let context = canvas.getContext("2d");

                      context.imageSmoothingEnabled = true;
                      context.imageSmoothingQuality = 1;

                      let w =
                        base64Img.width > 700 ? 700 : base64Img.width * ii;
                      let h = (w / watermarkImg.width) * watermarkImg.height;

                      let t_w = base64Img.width * 0.3;
                      let t_h = t_w * (tu_li.height / tu_li.width);

                      canvas.width = (base64Img.width + t_w) * scale;
                      canvas.height =
                        Math.max(base64Img.height + h, t_h) * scale;

                      context.fillStyle = "white";
                      context.fillRect(0, 0, canvas.width, canvas.height);
                      // that.drawWatermark(context, canvas.width, canvas.height);

                      context.drawImage(
                        base64Img,
                        0,
                        0,
                        base64Img.width * scale,
                        base64Img.height * scale
                      );
                      context.imageSmoothingEnabled = true;
                      context.imageSmoothingQuality = 1;

                      context.drawImage(
                        watermarkImg,
                        0,
                        base64Img.height * scale,
                        w * scale,
                        h * scale
                      );

                      context.imageSmoothingEnabled = true;
                      context.imageSmoothingQuality = 1;

                      context.drawImage(
                        tu_li,
                        base64Img.width * scale,
                        0,
                        t_w * scale,
                        t_h * scale
                      );
                      context.imageSmoothingEnabled = true;
                      context.imageSmoothingQuality = 1;

                      let resultImg = canvas.toDataURL("image/png");
                      DataUri.downloadDataUri(
                        resultImg,
                        this.graph_name + ".png"
                      );
                      vmson.$emit("export_png_success");
                    };
                  });
                };
              };
            },
            {
              backgroundColor: "white",
              quality: 1,
              padding: {
                top: 20,
                right: 50,
                bottom: 20,
                left: 50,
              },
            }
          );
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
#myDiv111 {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
}
.myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
}
#container {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss">
.outstanding_detail_dialog_zdh {
  z-index: 0 !important;
}
</style>
